
import { defineComponent, ref } from 'vue';

export default defineComponent({
  props: {
    placeholder: {
      type: String,
      default: null
    },
    clickSearch: {
      type: Function,
      required: true
    }
  },
  setup(props) {
    const keyword = ref();

    const searchKeyword = () => {
      props.clickSearch(keyword.value);
    };

    return {
      keyword,
      searchKeyword
    };
  }

});
